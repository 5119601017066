import { createSlice } from "@reduxjs/toolkit";

const initialNotarizationState = {
  allNotaries: [],
  allAdminNotaries: null,
  allAdminNotariesQuery: false,
  allAdminNotarization: null,
  allAdminNotarizationschedule: null,
  allAdminNotarizationcompleted: null,
  allAdminNotarizationinprogress: null,
  allAdminNotarizationQuery: false,
  filterNotaries: null,
  onlineNotaries: null,
  filterNotaries1: null,
  onlineNotaries1: null,
  bankDetails: [],
  documents: [],
  paymentCards: null,
  mypayment: null,
  clients: null,
  jobboards: [],
  allClients: null,
  BankAaccounts: null,
  payments: null,
  paymentDetails: null,
  defaultPaymentCard: null,
  defaultPaymentCard2: null,
  primaryNotary: null,
  totalCount: 0,
  allJournals: null,
  deletedJournals: null,
  allJobs: null,
  allCSVJournals: null,
  top5Journals: null,
  error: null,
  listLoading: false,
  addPaymentLoading: false,
  paynowLoading: false,
  draftLoading: false,
  scheduleLoading: false,
  actionsLoading: false,
  KBASettings: null,
  banksAdd: "",
  error: null,
  journalIDofNewNotarization: null,
  auditTrailData: null,
  witnesses: [],
  allWinessjournals: null,
  transactionHistory: null,
  reloadcallData: 0,
  customizeAllWitnessjournals: null,
  customizeJournals: null,
  customizedeleteJournals: null,
  customizeJobs: null,
  customizeTop5Journals: null,
  customizeAllAdminNotaries: null,
  custommizeTransactionHistory: null,
  currentkbasigners: [],
  currentjournalstatus: "",
  adobeURL: null,
  jitsiMeetingJWT: null, 
  jitsiAppID: null, 
  jitsiRoomName: null,
  jitsiMeetingLinks:[],
  allDocuments: [],
  getAllJournal: null,
  SingleJournalObj: null,
  templateURL: null,
  templateURLinsecure: null,
  templateURLAgreeURL: null,
  languages: null,
  notaryTypes: null,
  MyNotaries: null,
  MyClients: null,
  changeActiveStatusLoading: { id: "", loading: false },
  isWitnessAdded: false,
  customizeAllAdminStates: null,
  allAdminStates: null,
  allStates: null,
  allAdminStatesQuery: false,
  signerIdProofingDetails: null,
  verificationUrls:[]
};

export const callTypes = {
  list: "list",
  payment: "payment",
  paynow: "paynow",
  draft: "draft",
  schedule: "schedule",
  action: "action"
};

const prepareTime = dt => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let date = new Date(dt);
  let time = formatAMPM(date);
  let mon = months[date.getMonth()];
  let curDate = date.getDate();
  let year = date.getFullYear();
  let timezone = seconds_with_leading_zeros(date);
  return `${mon} ${curDate}, ${year} at ${time} (${timezone})`;
};

const formatAMPM = date => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const seconds_with_leading_zeros = dt => {
  return /\((.*)\)/.exec(dt.toString())[1];
};

export const notarizationSlice = createSlice({
  name: "notarization",
  initialState: initialNotarizationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) state.listLoading = false;
      else state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) state.listLoading = true;
      else if (action.payload.callType === callTypes.payment)
        state.addPaymentLoading = action.payload.action;
      else if (action.payload.callType === callTypes.paynow)
        state.paynowLoading = action.payload.action;
      else if (action.payload.callType === callTypes.draft)
        state.draftLoading = action.payload.action;
      else if (action.payload.callType === callTypes.schedule)
        state.scheduleLoading = action.payload.action;
      else if (action.payload.callType === callTypes.action)
        state.actionsLoading = true;
    },
    startFilteredNotariesCall: (state, action) => {
      state.filterNotaries = null;
    },
    startOnlineNotariesCall: (state, action) => {
      state.filterNotaries1 = null;
    },

    reloadcall: (state, action) => {
      state.reloadcallData = action.payload;
    },

    doneTemplate: (state, action) => {
      state.templateURL = action.payload;
    },

    doneTemplateunauthorized: (state, action) => {
      state.templateURLinsecure = action.payload;
    },

    doneAgreementTemplate: (state, action) => {
      state.templateURLAgreeURL = action.payload;
    },

    resetrecalldata: (state, action) => {
      state.reloadcallData = 0;
    },

    changeActiveStatus: (state, action) => {
      let loadingStatus = {
        id: action.payload.id,
        loading: action.payload.loading
      };
      state.changeActiveStatusLoading = loadingStatus;
    },
    changeUserActiveStatus: (state, action) => {
      let loadingStatus = {
        id: action.payload.id,
        loading: action.payload.loading
      };
      state.changeActiveStatusLoading = loadingStatus;
    },
    addBanksState: (state, action) => {
      state.banksAdd = action.payload.message;
    },

    getSingleJournalDetail: (state, action) => {
      state.SingleJournalObj = action.payload.journal;
      state.reloadcallData = 0;
    },

    fetchedKBASettings: (state, action) => {
      state.KBASettings = action.payload;
    },

    linkcopied: (state, action) => {},

    BankDetailsFetched: (state, action) => {
      state.bankDetails = action.payload;
    },
    witnessessFetched: (state, action) => {
      state.witnesses = action.payload;
    },
    witnessAdded: (state, action) => {
      state.isWitnessAdded = action.payload;
    },
    MyPaymentsFetched: (state, action) => {
      let data = action.payload.data;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let transactions = [];
      data.rows.map(transaction => {
        let formatedDate = "";
        const options = { year: "numeric", month: "long", day: "numeric" };
        formatedDate = new Date(transaction.createdAt).toLocaleDateString(
          undefined,
          options
        );
        let obj = {
          sno: SNo++,
          id: transaction.id,
          date: formatedDate,
          bankName: transaction.bankAccount.bankName,
          accNo:
            "xxxx" +
            " " +
            "xxxx" +
            " " +
            "xxxx" +
            " " +
            "xxxx" +
            " " +
            transaction.bankAccount.lastFour,
          amount: `$${transaction.amount / 100} ${transaction.status}`
        };
        transactions.push(obj);
      });
      state.transactionHistory = data;
      state.custommizeTransactionHistory = transactions;
    },
    notariesAdminFetched: (state, action) => {
      let data = action.payload.notaries.rows;
      let queries = action.payload.queries;
      let queryParams = action.payload.queryParams;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let notaries = [];
      data.map(notary => {
        let formatedDate = "";
        const options = { year: "numeric", month: "long", day: "numeric" };
        formatedDate = new Date(notary.createdAt).toLocaleDateString(
          undefined,
          options
        );
        let obj = {
          sno: SNo++,
          id: notary.id,
          name: `${notary.firstName} ${notary.lastName}`,
          signup_date: formatedDate,
          notarizations: notary.notaryJournals,
          email: notary.email,
          phone: notary.phone,
          state: notary.state,
          notaryExpiry: notary.notaryProfile.notaryExpiry,
          notaryProfile: notary.notaryProfile
        };
        notaries.push(obj);
      });
      state.customizeAllAdminNotaries = notaries;
      state.allAdminNotaries = action.payload.notaries;
      state.allAdminNotariesQuery = queryParams.length > 0 ? true : false;
    },

    notarizationAdminFetched: (state, action) => {
      state.allAdminNotarization = action.payload.journals;
    },

    notarizationAdminFetchedSchedule: (state, action) => {
      state.allAdminNotarizationschedule = action.payload.journals;
    },

    notarizationAdminFetchedCompleted: (state, action) => {
      state.allAdminNotarizationcompleted = action.payload.journals;
    },

    notarizationAdminFetchedProgress: (state, action) => {
      state.allAdminNotarizationinprogress = action.payload.journals;
    },

    notarizationAdminFetchedCompleted: (state, action) => {
      state.allAdminNotarizationcompleted = action.payload.journals;
    },

    notarizationAdminFetchedForCSV: (state, action) => {
      state.allCSVJournals = null;
      state.allCSVJournals = action.payload.journals;
    },

    getLanguagesDetail: (state, action) => {
      state.languages = null;

      let mainlanguages = [];
      action.payload.languages.map(language => {
        mainlanguages.push({
          label: language.name,
          value: language.id,
          id: language.id
        });
      });

      state.languages = mainlanguages;
    },

    NotaryTypesFetched: (state, action) => {
      state.notaryTypes = null;

      let mainnotaryTypes = [];
      action.payload.data.notaryTypes.map(notaryType => {
        mainnotaryTypes.push({
          label: notaryType.type,
          value: notaryType.id,
          id: notaryType.id
        });
      });

      state.notaryTypes = mainnotaryTypes;
    },

    fetchAdminUser: (state, action) => {
      let data = action.payload.notaries.rows;
      let queries = action.payload.queries;
      let queryParams = action.payload.queryParams;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let notaries = [];
      data.map(notary => {
        let formatedDate = "";
        const options = { year: "numeric", month: "long", day: "numeric" };
        formatedDate = new Date(notary.createdAt).toLocaleDateString(
          undefined,
          options
        );
        let obj = {
          sno: SNo++,
          id: notary.id,
          name: `${notary.firstName} ${notary.lastName}`,
          signup_date: formatedDate,
          notarizations: notary.userJournals,
          email: notary.email,
          phone: notary.phone,
          notaryProfile: notary.notaryProfile,
          primaryNotary: (notary?.userProfile && notary?.userProfile?.notaryId) ? notary?.userProfile?.User?.firstName + " " + notary?.userProfile?.User?.lastName : '-',
          roleId: notary.roleId
        };
        notaries.push(obj);
      });
      state.customizeAllAdminNotaries = notaries;
      state.allAdminNotaries = action.payload.notaries;
      state.allAdminNotariesQuery = queryParams.length > 0 ? true : false;
    },
    fetchNotaryStates: (state, action) => {
      let data = action.payload.states.rows;
      let queries = action.payload.queries;
      let queryParams = action.payload.queryParams;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let states = [];
      data.map(state => {
        let obj = {
          sno: SNo++,
          id: state.id,
          name: state.state,
          biometricEnabled: state.biometricEnabled
        };
        states.push(obj);
      });
      state.customizeAllAdminStates = states;
      state.allAdminStates = action.payload.states;
      state.allAdminStatesQuery = queryParams.length > 0 ? true : false;
    },
    fetchAllStates: (state, action) => {
      state.allStates = action.payload.states;
    },
    getWitnessJournals: (state, action) => {
      let data = action.payload.witnesses;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let witnesses = [];
      data.rows.map(witness => {
        let obj = {
          sno: SNo++,
          id: witness.id,
          status: witness.status,
          notaryName: `${witness.Journal.notary.firstName} ${witness.Journal.notary.lastName}`,
          customerName: `${witness.Journal.customer.firstName} ${witness.Journal.customer.lastName}`,
          obj: witness
        };
        witnesses.push(obj);
      });
      state.customizeAllWitnessjournals = witnesses;
      state.allWinessjournals = action.payload.witnesses;
    },
    questionsFetched: (state, action) => {
      state.BankAaccounts = action.payload;
    },
    bankAccountFetched: (state, action) => {
      state.BankAaccounts = action.payload;
    },
    notariesFetched: (state, action) => {
      state.allNotaries = action.payload;
    },
    filteredNotariesFetched: (state, action) => {
      state.filterNotaries = action.payload.notaries;
      state.onlineNotaries = action.payload.onlineNotaries;
    },
    filteredOnlineNotariesFetched: (state, action) => {
      state.filterNotaries1 = action.payload.notaries;
      state.onlineNotaries1 = action.payload.onlineNotaries;
    },
    clientsFetched: (state, action) => {
      state.clients = action.payload;
    },
    clientsAllFetched: (state, action) => {
      state.allClients = action.payload;
    },
    journalsCSVFetched: (state, action) => {
      let statuses = [
        "pending",
        "in progress",
        "completed",
        "draft",
        "scheduled",
        "initiated",
        "paid",
        "failed"
      ];
      let data = action.payload.data.Journals.rows;
      let user = action.payload.user;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let journalss = [];
      data.map((journal, index) => {
        let mainStatus =
          journal.status.toLowerCase() == "progress"
            ? "in progress"
            : journal.status.toLowerCase();
        let obj = {
          sno: SNo++,
          id: journal.id,
          notary:
            user === "notary"
              ? `${journal.customer.firstName} ${journal.customer.lastName}`
              : `${journal.notary !== null ? journal.notary.firstName : ""} ${
                  journal.notary !== null ? journal.notary.lastName : ""
                }`,
          type: journal.type === null ? "N/A" : journal.type,
          scheduledAt: `${
            journal.scheduledAt === null
              ? "-"
              : prepareTime(journal.scheduledAt)
          }`,
          endDate: `${
            journal.endDate === null ? "-" : prepareTime(journal.endDate)
          }`,
          noOfSigners: journal.Signers.length,
          status: statuses.indexOf(mainStatus)
        };
        journalss.push(obj);
      });
      // state.customizeJournals = journalss;
      state.allCSVJournals = action.payload.data.Journals;
      state.listLoading = false;
      state.error = null;
    },

    JobPostsFetched: (state, action) => {
      let statuses = [
        "pending",
        "in progress",
        "completed",
        "draft",
        "scheduled",
        "initiated",
        "paid",
        "failed",
        "rejected",
        "rescheduled",
        "scheduled-unclaimed"
      ];
      let data = action.payload.data.jobs.rows;

      let user = action.payload.user;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let journals = [];

      state.allJobs = action.payload.data.jobs;
      state.listLoading = false;
      state.error = null;
    },

    MyNotariesFetched: (state, action) => {
      state.MyNotaries = action.payload.data;
      state.listLoading = false;
      state.error = null;
    },

    myPaymentforExistingJournal: (state, action) => {
      state.mypayment = action.payload.data;
      state.listLoading = false;
      state.error = null;
    },

    clientsApproved: (state, action) => {
      let myNot = [];

      for (let chk = 0; chk < action.payload.data.rows.length; chk++)
        myNot.push(action.payload.data.rows[chk].clientId);

      state.MyClients = myNot;
      state.listLoading = false;
      state.error = null;
    },

    journalsDeletedFetched: (state, action) => {
      let statuses = [
        "pending",
        "in progress",
        "completed",
        "draft",
        "scheduled",
        "initiated",
        "paid",
        "failed",
        "rejected",
        "rescheduled",
        "scheduled-unclaimed"
      ];
      let data = action.payload.data.deletedJournals.rows;
      let user = action.payload.user;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let journals = [];
      data.map((journal, index) => {
        let mainStatus =
          journal.status.toLowerCase() == "progress"
            ? "in progress"
            : journal.status.toLowerCase();
        let obj = {
          sno: SNo++,
          id: journal.id,
          notary:
            user === "ps"
              ? `${journal.customer.firstName} ${journal.customer.lastName}`
              : `${journal.notary !== null ? journal.notary.firstName : ""} ${
                  journal.notary !== null ? journal.notary.lastName : ""
                }`,
          type: journal.type === null ? "N/A" : journal.type,
          scheduledAt: `${
            journal.scheduledAt === null
              ? "-"
              : prepareTime(journal.scheduledAt)
          }`,
          endDate: `${
            journal.endDate === null ? "-" : prepareTime(journal.endDate)
          }`,
          noOfSigners: journal.Signers.length,
          status: statuses.indexOf(mainStatus)
        };
        journals.push(obj);
      });
      state.customizedeleteJournals = journals;
      state.deletedJournals = action.payload.data.deletedJournals;
      state.listLoading = false;
      state.error = null;
    },

    journalsFetched: (state, action) => {
      let statuses = [
        "pending",
        "in progress",
        "completed",
        "draft",
        "scheduled",
        "initiated",
        "paid",
        "failed",
        "rejected",
        "rescheduled",
        "scheduled-unclaimed"
      ];
      let data = action.payload.data.Journals.rows;
      let user = action.payload.user;
      let queries = action.payload.queries;
      let SNo = queries.pageNumber * queries.pageSize + 1 - queries.pageSize;
      let journals = [];
      data.map((journal, index) => {
        let mainStatus =
          journal.status.toLowerCase() == "progress"
            ? "in progress"
            : journal.status.toLowerCase();
        let obj = {
          sno: SNo++,
          id: journal.id,
          notary:
            user === "notary"
              ? `${journal.customer.firstName} ${journal.customer.lastName}`
              : `${journal.notary !== null ? journal.notary.firstName : ""} ${
                  journal.notary !== null ? journal.notary.lastName : ""
                }`,
          type: journal.type === null ? "N/A" : journal.type,
          scheduledAt: `${
            journal.scheduledAt === null
              ? "-"
              : prepareTime(journal.scheduledAt)
          }`,
          endDate: `${
            journal.endDate === null ? "-" : prepareTime(journal.endDate)
          }`,
          noOfSigners: journal.Signers.length,
          status: statuses.indexOf(mainStatus)
        };
        journals.push(obj);
      });
      state.customizeJournals = journals;
      state.allJournals = action.payload.data.Journals;
      state.listLoading = false;
      state.error = null;
    },
    top5JournalsFetched: (state, action) => {
      let statuses = [
        "pending",
        "in progress",
        "completed",
        "draft",
        "scheduled",
        "initiated",
        "paid",
        "failed",
        "rejected",
        "rescheduled",
        "scheduled-unclaimed"
      ];
      let data = action.payload.data.Journals.rows;
      let user = action.payload.user;
      let journals = [];
      data.map((journal, index) => {
        let mainStatus =
          journal.status.toLowerCase() == "progress"
            ? "in progress"
            : journal.status.toLowerCase();
        let obj = {
          sno: index + 1,
          id: journal.id,
          notary_name:
            user === "notary"
              ? `${journal.customer.firstName} ${journal.customer.lastName}`
              : `${journal.notary !== null ? journal.notary.firstName : ""} ${
                  journal.notary !== null ? journal.notary.lastName : ""
                }`,
          type: journal.type === null ? "N/A" : journal.type,
          scheduledAt: `${
            journal.scheduledAt === null
              ? "-"
              : prepareTime(journal.scheduledAt)
          }`,
          endDate: `${
            journal.endDate === null ? "-" : prepareTime(journal.endDate)
          }`,
          noOfSigners: journal.Signers.length,
          status: statuses.indexOf(mainStatus)
        };
        journals.push(obj);
      });
      state.customizeTop5Journals = journals;
      state.top5Journals = action.payload.data.Journals;
      state.listLoading = false;
      state.error = null;
    },
    paymentCardsFetched: (state, action) => {
      state.paymentCards = action.payload;
    },

    defaultPaymentCardsFetched2: (state, action) => {
      state.defaultPaymentCard2 = action.payload;
    },
    defaultPaymentCardsFetched: (state, action) => {
      state.defaultPaymentCard = action.payload;
    },
    cardDefaulted: (state, action) => {
      state.paymentCards = action.payload.cards;
      state.defaultPaymentCard = action.payload.defaultCard;
    },
    paymentCardAdded: (state, action) => {
      let paymentCards = state.paymentCards;
      let newCard = action.payload;
      let paymentCardsMatched = paymentCards.filter(
        el => el.lastFour === newCard.lastFour
      );
      paymentCards = paymentCards.filter(
        el => el.lastFour !== newCard.lastFour
      );
      if (paymentCardsMatched.length > 0 && paymentCardsMatched[0].default)
        newCard.default = true;

      if (newCard.default) {
        paymentCards.map(card => (card.default = false));
        paymentCards.push(newCard);
        state.defaultPaymentCard = newCard;
      } else paymentCards.push(newCard);

      state.paymentCards = paymentCards;
      state.addPaymentLoading = false;
    },
    paymentDetailsFetched: (state, action) => {
      state.paymentDetails = action.payload;
    },
    PlatformFeePaid: (state, action) => {
      state.payments = action.payload;
      state.paynowLoading = false;
    },
    documentUploaded: (state, action) => {
      action.payload.map(file => state.documents.push(file));
    },
    deleteDocument: (state, action) => {
      state.documents = state.documents.filter(
        el => el.id !== JSON.parse(action.payload)
      );
    },
    documentUpdated: (state, action) => {
      state.documents = action.payload;
    },
    primaryNotaryFetched: (state, action) => {
      state.primaryNotary = action.payload;
    },
    primaryNotarySelected: (state, action) => {
      state.primaryNotary = action.payload;
    },
    clearDocuments: (state, action) => {
      state.documents = [];
    },
    clearPayment: (state, action) => {
      state.payments = null;
    },
    clearAllState: (state, action) => {
      state.allNotaries = [];
      state.documents = [];
      state.paymentCards = null;
      state.clients = null;
      state.allClients = null;
      state.payments = null;
      state.paymentDetails = null;
      state.defaultPaymentCard = null;
      state.primaryNotary = null;
      state.totalCount = 0;
      state.allJournals = null;
      state.top5Journals = null;
      state.customizeJournals = null;
      state.customizeTop5Journals = null;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      state.allWinessjournals = null;
      state.transactionHistory = null;
      state.filterNotaries = null;
      state.allAdminNotaries = null;
    },
    notarizationAdded: (state, action) => {
      state.journalIDofNewNotarization = action.payload;
    },
    getcurrentsigners: (state, action) => {
      state.currentkbasigners = action.payload;
    },
    getDocuments: (state, action) => {
      state.allDocuments = action.payload;
    },
    getcurrentjournalstatus: (state, action) => {
      state.currentjournalstatus = action.payload.subStatus;
    },
    doneWitness: (state, action) => {
      state.adobeURL = action.payload;
    },
    auditTrailFetched: (state, action) => {
      state.auditTrailData = action.payload;
    },
    getJitsiMeetingJWT: (state, action) => {
      console.log(action.payload);
      state.jitsiMeetingJWT = action.payload.jitsiMeetingJWT;
      state.jitsiAppID = action.payload.jitsiAppID;
      state.jitsiRoomName = action.payload.jitsiRoomName;
    },
    getJitsiMeetingLinks: (state, action) => {
      state.jitsiMeetingLinks = action.payload;
    },
    getAllJournalDetails: (state, action) => {
      state.getAllJournal = action.payload;
    },
    signerIdProofingDetails: (state, action) => {
      state.signerIdProofingDetails = action.payload;
    },
    deleteAdminUser: (state, action) => {
      // state.adminUser = state.documents.filter(el => el.id !== JSON.parse(action.payload));
    },
    getVerificationUrls: (state, action) => {
      state.verificationUrls = action.payload;
    },
  }
});
