/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import * as auth from "../Auth";
import * as actions from "../Auth/_redux/AuthInit";
import { MultiSelect } from "react-multi-select-component";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import TextField from "@material-ui/core/TextField";
import AccountInformation from "./AccountInformation";
import Alert from "@material-ui/lab/Alert";
import { injectIntl } from "react-intl";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Modal, Button } from "react-bootstrap";
import ShowNotification from "../../components/new_notarization/ShowNotification";
import * as notarizeActions from "../../components/_redux/notarization_redux/notarizationActions";

function NotaryProfile(props) {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

  const options = [
    { label: "Albanian", value: "Albanian" },
    {
      label: "American Sign Language (ASL)",
      value: "American Sign Language (ASL)"
    },
    { label: "Amharic", value: "Amharic" },
    { label: "Arabic", value: "Arabic" },
    { label: "Armenian", value: "Armenian" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bengali", value: "Bengali" },
    { label: "Bislama", value: "Bislama" },
    { label: "Burmese", value: "Burmese" },
    { label: "Catalan", value: "Catalan" },
    { label: "Chichewa", value: "Chichewa" },
    { label: "Chinese,", value: "Chinese," },
    { label: "Mandarin", value: "Mandarin" },
    { label: "Czech", value: "Czech" },
    { label: "Danish", value: "Danish" },
    { label: "Dari", value: "Dari" },
    { label: "Dhivehi", value: "Dhivehi" },
    { label: "Dutch", value: "Dutch" },
    { label: "Dzongkha", value: "Dzongkha" },
    { label: "English", value: "English" },
    { label: "Estonian", value: "Estonian" },
    { label: "Finnish", value: "Finnish" },
    { label: "French", value: "French" },
    { label: "Frisian", value: "Frisian" },
    { label: "Georgian", value: "Georgian" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Guaraní", value: "Guaraní" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Icelandic", value: "Icelandic" },
    { label: "Indonesian", value: "Indonesian" },
    { label: "Irish", value: "Irish" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Kazakh", value: "Kazakh" },
    { label: "Khmer", value: "Khmer" },
    { label: "Kikongo", value: "Kikongo" },
    { label: "Kinyarwanda", value: "Kinyarwanda" },
    { label: "Korean", value: "Korean" },
    { label: "Kyrgyz", value: "Kyrgyz" },
    { label: "Lao", value: "Lao" },
    { label: "Latvian", value: "Latvian" },
    { label: "Lithuanian", value: "Lithuanian" },
    { label: "Luxembourgish", value: "Luxembourgish" },
    { label: "Macedonian", value: "Macedonian" },
    { label: "Malagasy", value: "Malagasy" },
    { label: "Malay", value: "Malay" },
    { label: "Maltese", value: "Maltese" },
    { label: "Moldovan", value: "Moldovan" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegrin", value: "Montenegrin" },
    { label: "Ndebele", value: "Ndebele" },
    { label: "Nepali", value: "Nepali" },
    { label: "Norwegian", value: "Norwegian" },
    { label: "Palauan", value: "Palauan" },
    { label: "Persian", value: "Persian" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Quechua", value: "Quechua" },
    { label: "Romanian", value: "Romanian" },
    { label: "Russian", value: "Russian" },
    { label: "Samoan", value: "Samoan" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sango", value: "Sango" },
    { label: "Serbian", value: "Serbian" },
    { label: "Seychellois Creole", value: "Seychellois Creole" },
    { label: "Sinhala", value: "Sinhala" },
    { label: "Slovak", value: "Slovak" },
    { label: "Slovene", value: "Slovene" },
    { label: "Somali", value: "Somali" },
    { label: "Sotho", value: "Sotho" },
    { label: "Spanish", value: "Spanish" },
    { label: "Standard Chinese", value: "Standard Chinese" },
    { label: "Swahili", value: "Swahili" },
    { label: "Swahili", value: "Swahili" },
    { label: "Swati", value: "Swati" },
    { label: "Swedish", value: "Swedish" },
    { label: "Tetum", value: "Tetum" },
    { label: "Thai", value: "Thai" },
    { label: "Tigrinya", value: "Tigrinya" },
    { label: "Tok Pisin", value: "Tok pisin" },
    { label: "Tswana", value: "Tswana" },
    { label: "Turkish", value: "Turkish" },
    { label: "Turkmen", value: "Turkmen" },
    { label: "Ukrainian", value: "Ukrainian" },
    { label: "Uzbek", value: "Uzbek" },
    { label: "Vietnamese", value: "Vietnamese" },
    { label: "Yoruba", value: "Yoruba" }
  ];
  const typeOfNotary = [
    { label: "Commisioned Notary", value: 1, disabled: true },
    { label: "Loan Signing Agent", value: 2 },
    { label: "Lawyer", value: 3 }
  ];

  const { auth, notarization } = useSelector(state => state);
  const { allStates } = notarization;

  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  const location = useLocation();
  const [lang, setLang] = useState([]);
  const [selectedValues, setselectedValues] = useState([]);
  const [typeNotary, setTypeNotary] = useState([
    { label: "Commisioned Notary", value: 1 }
  ]);
  const [FormUpdateMSG, setFormUpdateMSG] = useState(false);
  const [showModal, setShow] = useState(false);
  const [errormsg, seterrormsg] = useState("");
  const [notNumber, setNotNumber] = useState(false);
  const [profileUpdate, setProfileUpdate] = useState(false);
  const [removedNotaryCommisions, setRemovedNotaryCommisions] = useState([]);
  const [notaryCommissions, setNotaryCommissions] = useState([
    { id: "", commissionNo: "", commissionState: "", expiryDate: "" }
  ]);

  const handleClose = () => setShow(false);
  const [notification, setNotification] = useState({
    show: false,
    msg: "",
    variant: ""
  });
  const handleShow = () => setShow(true);
  const [showModalVerification, setShowModalVerification] = useState(false);
  const handleShowVerification = () => setShowModalVerification(true);
  const handleCloseVerification = () => setShowModalVerification(false);
  const dispatch = useDispatch();
  const {
    user,
    authToken,
    passportID,
    insurancePolicyID,
    profileAvatarID,
    uploadingImageNotary,
    stripeVerficationAccountStatus,
    stripeVerficationUrl
  } = useSelector(state => state.auth, shallowEqual);

  useEffect(() => {
    dispatch(actions.getStripVerification(authToken));
    setLang(
      user.Languages.map(x => {
        return { value: x.name, label: x.name };
      })
    );
    if (user.notaryProfile && user.notaryProfile.notaryTypes.length > 0)
      setTypeNotary(
        user.notaryProfile.notaryTypes.map(x => {
          return { value: x.id, label: x.type };
        })
      );

    if (user.notaryCommissions) {
      setNotaryCommissions(
        user.notaryCommissions.map(x => {
          return {
            id: x.id,
            commissionNo: x.commissionNo,
            commissionState: x.commissionState,
            expiryDate: x.expiryDate
          };
        })
      );
    }
  }, [user]);

  useEffect(() => {
    // get all states without auth applied on middleware...
    dispatch(notarizeActions.fetchAllStates(headers));
  }, []);

  const requestAccountAccess = () => {
    dispatch(notarizeActions.requestAccountAccess(headers))
    .then((response)=>{
      setNotification({
        show: true,
        msg: response.data.message,
        variant: "success"
      })
      setTimeout(() => {
        window.location.href = '/#/logout'
      }, 3000);
    })
    .catch((error)=> {
      setNotification({
        show: true,
        msg: error.response.data.message,
        variant: "error"
      })
    });
  }

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    seterrormsg("");

    let languages = [];
    let notaryTypes = [];
    lang.map(data => {
      languages.push(data.value);
    });
    typeNotary.map(data => {
      notaryTypes.push(data.value);
    });

    let errorInCommisiions = false;
    values.notaryCommissions.map(commision => {
      if (
        "" == commision.commissionNo ||
        "" == commision.commissionState ||
        "" == commision.expiryDate
      ) {
        errorInCommisiions = true;
      }
    });
    if (errorInCommisiions) {
      seterrormsg("Commission Fields should not be empty.");
      return false;
    }

    if (!/^[a-zA-Z0-9]*$/g.test(values.firstName)) {
      seterrormsg("Please Provide Correct First Name");
      return false;
    }

    if (!/^[a-zA-Z0-9]*$/g.test(values.lastName)) {
      seterrormsg("Please Provide Correct Last Name");
      return false;
    }
    if (!/^[a-zA-Z0-9]*$/g.test(values.middleName)) {
      seterrormsg("Please Provide Correct Middle Name");
      return false;
    }
    const updatedUser = {
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        suffix: values.suffix,
        address: values.address,
        state: values.state,
        city: values.city,
        zip: values.zip,
        phone: values.phone,
        profile_pic: profileAvatarID ? profileAvatarID[0].id : null
      },

      commissions: values.notaryCommissions,
      notaryProfile: {
        //    "refCode": values.refCode,
        notaryExpiry: values.notaryExpiry,
        commissionNo: values.commissionNo,
        passport: passportID ? passportID[0].id : null,
        insurancePolicy: insurancePolicyID ? insurancePolicyID[0].id : null,
        notaryTypes: notaryTypes
      },
      languages: languages
    };

    if (!updatedUser.user.profile_pic) delete updatedUser.user.profile_pic;

    if (!updatedUser.notaryProfile.passport) {
      delete updatedUser.notaryProfile.passport;
      if (!user.notaryProfile || !user.notaryProfile.passport_file) {
        seterrormsg("Please Provice Passport");
        return false;
      }
    }

    if (!updatedUser.notaryProfile.insurancePolicy) {
      delete updatedUser.notaryProfile.insurancePolicy;

      if (!user.notaryProfile || !user.notaryProfile.insurance_policy) {
        seterrormsg("Please Provice Insurance");
        return false;
      }
    }
    if (removedNotaryCommisions.length) {
      dispatch(
        actions.deleteCommisionsCall(
          removedNotaryCommisions,
          authToken,
          setFormUpdateMSG
        )
      );
    }
    dispatch(actions.updateProfile(updatedUser, authToken, setFormUpdateMSG));

    setTimeout(() => {
      setNotNumber(false);
      setloading(false);
      setSubmitting(false);

      if (!auth.user.notaryProfile.isApproved && !auth.user.notaryProfile.isCancelled)
        window.location.href = "/#/user-profile#under-review";
    }, 1000);
  };
  // UI Helpers
  const refCodefunction = () => {
    let referCode;
    if (user.Role.code === "notary" && user["notaryProfile"].refCode !== null) {
      if (user["notaryProfile"].refCode.includes("http")) {
        referCode = user["notaryProfile"].refCode;
      } else {
        referCode = MIDDLEWARE_URL + "/s/" + user["notaryProfile"].refCode;
      }
    }
    // return user.Role.code == "notary" && user["notaryProfile"] != null ? "https://dev-cyberzeit.codup.io/s/" + user["notaryProfile"].refCode : ""
    return referCode;
  };

  const initialValues = {
    profile_picture: user.profile_picture,
    firstName: user.firstName,
    lastName: user.lastName,
    middleName: user.middleName,
    suffix: user.suffix,
    email: user.email,
    phone: user.phone,
    refCode: refCodefunction(),
    address: user.address,
    city: user.city,
    state: user.state,
    zip: user.zip,
    commissionNo:  user.notaryProfile ? user.notaryProfile.commissionNo : '',
    notaryCommissions: user.notaryCommissions ? user.notaryCommissions : [],
    notaryType:
      user.Role.code === "notary" && user["notaryProfile"] !== null
        ? user["notaryProfile"].notaryType
        : "",
    Role: user.Role,
    Languages: user.Languages,
    passport: user.passport,
    insurancePolicy: user.insurancePolicy,
    notaryExpiry:
      user.Role.code === "notary" && user["notaryProfile"] !== null
        ? user["notaryProfile"].notaryExpiry
        : ""
  };
  const { intl } = props;
  const [loading, setloading] = useState(false);
  const NotaryProfileSchema = Yup.object().shape({
    // profile_pic: Yup.string(),
    firstName: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    lastName: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    phone: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    address: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    city: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    state: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    zip: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    suffix: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .matches(/^[A-Za-z.]+$/gi, "Must be only characters")
      .max(5, "Must not exceed 5 letters"),
    notaryExpiry: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
      commissionNo: Yup.string()
      .trim("cannot include leading and trailing spaces")
      .strict(true)
      .nullable()
      .matches(/^[0-9]+$/, "Must be only numbers")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const showprofilePic = e => {
    var reader = new FileReader();
    reader.onload = function(e) {
      //user.profile_picture.baseUrl =e.target.result
      user.tempprofilepicture = e.target.result;
      dispatch({ type: "temporary_profile", payload: user.tempprofilepicture });
    };

    reader.readAsDataURL(document.querySelector("input[type='file']").files[0]);
  };
  const getAvatar = file => {
    let imageExtension = file.target.files[0].name.split(".")[1];
    if (
      imageExtension.toLowerCase() === "jpg" ||
      imageExtension.toLowerCase() === "png" ||
      imageExtension.toLowerCase() === "jpeg" ||
      imageExtension.toLowerCase() === "heic"
    ) {
      let formData = new FormData();
      formData.append("userId", user.id);
      formData.append("type", "proficPic");
      formData.append("file", file.target.files[0]);
      dispatch(
        actions.updateNotaryProfileAvatar(formData, authToken, setProfileUpdate)
      );
    } else {
      setNotification({
        show: true,
        msg: "Only JPG, JPEG, PNG, Image format is acceptable!",
        variant: "error"
      });
    }
  };
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const passportDoc = (file, rejected) => {
    if (rejected.length > 0) {
      let errorCode = rejected[0].errors[0].code;
      let errorMessage =
        'Invalid file! All files except executions files (e.g. ".exe")';
      if (errorCode === "file-invalid-type")
        errorMessage =
          'Invalid file! All files except executions files (e.g. ".exe")';
      else if (errorCode === "file-too-large")
        errorMessage = "Acceptable maximum file size is 10MB!";
      else if (errorCode === "too-many-files")
        errorMessage = "You can upload only 1 file!";
      setNotification({ show: true, msg: errorMessage, variant: "error" });
    } else {
      let formData = new FormData();
      formData.append("userId", user.id);
      formData.append("type", "passport");
      formData.append("file", file[0]);
      if (!user.notaryProfile.passport_file)
        user.notaryProfile.passport_file = [];
      user.notaryProfile.passport_file.filename = file[0].name;
      dispatch(actions.updatePassportProfile(formData, authToken));
    }
  };
  const insurancePolicy = (file, rejected) => {
    if (rejected.length > 0) {
      let errorCode = rejected[0].errors[0].code;
      let errorMessage =
        'Invalid file! All files except executions files (e.g. ".exe")';
      if (errorCode === "file-invalid-type")
        errorMessage =
          'Invalid file! All files except executions files (e.g. ".exe")';
      else if (errorCode === "file-too-large")
        errorMessage = "Acceptable maximum file size is 10MB!";
      else if (errorCode === "too-many-files")
        errorMessage = "You can upload only 1 file!";
      setNotification({ show: true, msg: errorMessage, variant: "error" });
    } else {
      let formData = new FormData();
      formData.append("userId", user.id);
      formData.append("type", "insurancePolicy");
      formData.append("file", file[0]);
      if (!user.notaryProfile.insurance_policy)
        user.notaryProfile.insurance_policy = [];
      user.notaryProfile.insurance_policy.filename = file[0].name;
      dispatch(actions.uploadInsurancePolicy(formData, authToken));
    }
  };

  const handleChangeCheckBox = event => {
    setLang(event);
  };
  const handleChangeCheckBoxTypeNotary = event => {
    setTypeNotary(event);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ show: false, msg: "", variant: "" });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: NotaryProfileSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!isNaN(values.phone)) {
        saveUser(values, setStatus, setSubmitting);
      } else {
        setNotNumber(true);
      }
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  const removePic = () => {
    let picID = user.profile_pic;
    dispatch(actions.deleteProfile(picID, authToken));
    setShow(false);
    user.tempprofilepicture = toAbsoluteUrl("/media/users/blank.png");
  };

  const add_fields = () => {
    formik.setFieldValue("notaryCommissions", [
      ...formik.values.notaryCommissions,
      { id: "", commissionNo: "", commissionState: "", expiryDate: "" }
    ]);
    setNotaryCommissions([
      ...notaryCommissions,
      { id: "", commissionNo: "", commissionState: "", expiryDate: "" }
    ]);
  };

  const remove_fields = (commissionId, i) => {
    let commisionNotary = JSON.parse(
      JSON.stringify(formik.values.notaryCommissions)
    );
    let removedCommissions = [];
    if (commissionId) {
      commisionNotary.splice(i, 1);
      removedCommissions = [...removedNotaryCommisions, commissionId];
    } else {
      commisionNotary.splice(i, 1);
    }
    setRemovedNotaryCommisions(removedCommissions);
    setNotaryCommissions(commisionNotary);
    formik.setFieldValue("notaryCommissions", commisionNotary);
  };

  return (
    <>
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      {user.Role.code === "notary" ? (
        <form
          id="notary_profile_form"
          className="card card-custom"
          onSubmit={formik.handleSubmit}
        >
          {loading && <ModalProgressBar />}
          {/* begin::Header */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark mt-3">
                My Notary Profile
              </h3>
            </div>
            <div className="card-toolbar">
              { user.notaryProfile.isCancelled && 
                (
                  <Button
                    variant="warning"
                    className="mr-2"
                    onClick={requestAccountAccess}
                  >
                    Request Access
                  </Button>
                )
              }
              <button
                type="submit"
                className="btn mr-2"
                style={{ backgroundColor: "#4A7DFF", color: "white" }}
                disabled={lang.length < 1 ? "disabled" : ""}
              >
                Save Changes
                {formik.isSubmitting}
              </button>
              <Link to="/dashboard" className="btn btn-secondary">
                Cancel
              </Link>
              {user.notaryProfile.profile_completed &&
              user.notaryProfile.isApproved ? (
                <Button
                  className="btn ml-2"
                  style={{ backgroundColor: "#4A7DFF", color: "white" }}
                  onClick={handleShowVerification}
                >
                  Stripe Verification
                </Button>
              ) : (
                ""
              )}

              <Modal
                show={showModalVerification}
                onHide={handleCloseVerification}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Stripe Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>{`Status:  ${stripeVerficationAccountStatus}`}</h4>
                  <h4>
                    Link: <a href={stripeVerficationUrl}>Click Here</a>
                  </h4>
                </Modal.Body>
              </Modal>
            </div>
          </div>
          {FormUpdateMSG ? (
            <Alert severity="success">Changes are Successful</Alert>
          ) : (
            ""
          )}
          {/* end::Header */}
          {/* begin::Form */}

          {location.hash &&
          "#profile-not-completed" === location.hash &&
          user.notaryProfile &&
          !user.notaryProfile.profile_completed ? (
            <Alert severity="error">Your profile is not completed yet.</Alert>
          ) : (
            ""
          )}

          {location.hash &&
          "#profile-not-completed" === location.hash &&
          user.notaryProfile &&
          !user.notaryProfile.profile_completed &&
          errormsg ? (
            <Alert severity="error">{errormsg}</Alert>
          ) : (
            ""
          )}

          {location.hash &&
          "#under-review" === location.hash &&
          user.notaryProfile &&
          !user.notaryProfile.isApproved &&
          !errormsg ? (
            <Alert severity="error">
              Your profile is Under Review by Admin.
            </Alert>
          ) : (
            ""
          )}

          {profileUpdate ? (
            <Alert severity="success">Profile Image Update Successfully</Alert>
          ) : (
            ""
          )}
          {notNumber ? (
            <Alert severity="error" onClose={() => setNotNumber(false)}>
              Phone Number field Accept only numbers
            </Alert>
          ) : (
            ""
          )}
          <div className="form">
            <div className="card-body">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Profile Picture
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div
                    className="image-input image-input-outline"
                    id="kt_profile_avatar"
                    style={{
                      // backgroundImage: `url(${(user.tempprofilepicture) ? user.tempprofilepicture : (user.profile_picture) ? ('"' + user.profile_picture.baseUrl + user.profile_picture.path + '"') : toAbsoluteUrl(
                      //     "/media/users/blank.png"
                      // )}`,
                      backgroundImage: `url(${
                        uploadingImageNotary
                          ? toAbsoluteUrl("/media/own/dp_loader.gif")
                          : user.profile_picture
                          ? `"${user.profile_picture.baseUrl}/${user.profile_picture.path}"`
                          : toAbsoluteUrl("/media/users/blank.png")
                      })`
                    }}
                  >
                    <div
                      className="image-input-wrapper"
                      style={{
                        backgroundImage: `url(${
                          uploadingImageNotary
                            ? toAbsoluteUrl("/media/own/dp_loader.gif")
                            : user.profile_picture
                            ? `"${user.profile_picture.baseUrl}${user.profile_picture.path}"`
                            : toAbsoluteUrl("/media/users/blank.png")
                        })`
                      }}
                    />
                    <label
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i className="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="profile_pic"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={getAvatar}
                        // {...formik.getFieldProps("pic")}
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Cancel avatar"
                    >
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    {user.profile_picture ? (
                      <span
                        onClick={handleShow}
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Remove avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    ) : (
                      ""
                    )}
                    <Modal show={showModal} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Profile Image</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are You Sure To Delete Profile Image
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="danger" onClick={removePic}>
                          Delete
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <span className="form-text text-muted">
                    Allowed file types: png, jpg, jpeg.
                  </span>
                </div>
              </div>
              {/* begin::Form Group */}

              {auth.user &&
              auth.user.notaryEmail &&
              auth.user.notaryEmail.email ? (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Adobe Email
                    </label>

                    <div className="col-lg-9 col-xl-6">
                      {" "}
                      <input
                        type="text"
                        placeholder="Adobe Email"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "adobeEmail"
                        )}`}
                        name="adobeEmail"
                        value={auth.user.notaryEmail.email}
                      />
                      This is your Adobe Cloud (a/k/a back office) Login information; this is NOT an email address for you. Use this to create a template (pre-tag) for your notarization sessions.
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Adobe Password
                    </label>

                    <div className="col-lg-9 col-xl-6">
                      {" "}
                      <input
                        type="text"
                        placeholder="Adobe Password"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "adobePassword"
                        )}`}
                        name="adobePassword"
                        value={"CyberizeIt1!"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  First Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="First Name"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "firstName"
                      )}`}
                      name="firstName"
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.firstName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Middle Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Middle Name"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "middleName"
                      )}`}
                      name="middleName"
                      {...formik.getFieldProps("middleName")}
                    />
                    {formik.touched.middleName && formik.errors.middleName ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.middleName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Last Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "lastName"
                      )}`}
                      name="lastName"
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.lastName}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* begin::Form Group */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Suffx
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Suffx"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "suffix"
                      )}`}
                      name="suffix"
                      {...formik.getFieldProps("suffix")}
                      maxLength={5}
                    />
                    {formik.touched.suffix && formik.errors.suffix ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.suffix}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Email
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-at"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Email Address"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "email"
                      )}`}
                      name="email"
                      disabled={true}
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Phone
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="phone"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "phone"
                      )}`}
                      name="phone"
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.phone}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Client Referral Link
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="refCode"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "refCode"
                      )}`}
                      name="refCode"
                      disabled={true}
                      {...formik.getFieldProps("refCode")}
                    />
                    This is the Link you send to clients so that they will connect with you for a notarization
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Address
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Address"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "address"
                      )}`}
                      name="address"
                      {...formik.getFieldProps("address")}
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.address}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">City</label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="City"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "city"
                      )}`}
                      name="city"
                      {...formik.getFieldProps("city")}
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">
                          {formik.errors.city}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  State
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    placeholder="Select state"
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "state"
                    )}`}
                    name="state"
                    value={user.state}
                    {...formik.getFieldProps("state")}
                  >
                    <option value="none" selected>
                      Select State
                    </option>

                    {allStates &&
                      allStates.rows.map(el => (
                        <option value={el.state}>{el.state}</option>
                      ))}
                  </select>
                  {formik.touched.state && formik.errors.state ? (
                    <div
                      className="fv-plugins-message-container"
                      style={{ color: "red" }}
                    >
                      <div className="fv-help-block">{formik.errors.state}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">Zip</label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      placeholder="Zip Code"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "zip"
                      )}`}
                      name="zip"
                      {...formik.getFieldProps("zip")}
                    />
                    {formik.touched.zip && formik.errors.zip ? (
                      <div
                        className="fv-plugins-message-container"
                        style={{ color: "red" }}
                      >
                        <div className="fv-help-block">{formik.errors.zip}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* code commented for add more multiple commission numbers */}
              {/* <div className="form-group row border-top m-0">
                <div className="col-lg-12 col-xl-12 text-right">
                  <div className="col-lg-12 col-xl-12">
                    <input
                      type="button"
                      class="btn btn-secondary"
                      onClick={() => add_fields()}
                      value="Add More"
                    />
                  </div>
                </div>
              </div> */}
            
              <div>
                
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Commission Number
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        placeholder="Commission Number"
                        className={`form-control form-control-lg form-control-solid ${getInputClasses(
                          "firstName"
                        )}`}
                        name="commissionNo"
                        {...formik.getFieldProps("commissionNo")}
                      />
                      {formik.touched.commissionNo &&
                      formik.errors.commissionNo ? (
                        <div
                          className="fv-plugins-message-container"
                          style={{ color: "red" }}
                        >
                          <div className="fv-help-block">
                            {formik.errors.commissionNo}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                
               
              </div>

               {/* code commented for multiple commission numbers */}
              {/* {formik.values.notaryCommissions &&
                formik.values.notaryCommissions.map((commision, i) => {
                  let disabled = false;
                  return (
                    <div>
                      <div className="">
                        <div className="">
                          <div>
                            <TextField
                              type="hidden"
                              placeholder="Commission id"
                              name={`notaryCommissions[${i}].commissionId`}
                              {...formik.getFieldProps(
                                "notaryCommissions[" + i + "].commissionId"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                          Commission Number
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <div>
                            <input
                              type="text"
                              placeholder="Commission Number"
                              className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                "notaryCommissions[" + i + "].commissionNo"
                              )}`}
                              name={`notaryCommissions[${i}].commissionNo`}
                              {...formik.getFieldProps(
                                "notaryCommissions[" + i + "].commissionNo"
                              )}
                            />
                            {formik.touched.commissionNo &&
                            formik.errors.commissionNo ? (
                              <div
                                className="fv-plugins-message-container"
                                style={{ color: "red" }}
                              >
                                <div className="fv-help-block">
                                  {formik.errors.commissionNo}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                          Expiry Date
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <div>
                            <TextField
                              className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                "notaryCommissions[" + i + "].expiryDate"
                              )}`}
                              id="date"
                              type="date"
                              color="secondary"
                              name={`notaryCommissions[${i}].expiryDate`}
                              {...formik.getFieldProps(
                                "notaryCommissions[" + i + "].expiryDate"
                              )}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                min: new Date().toISOString().split("T")[0]
                              }}
                            />
                            {formik.touched.expiryDate &&
                            formik.errors.expiryDate ? (
                              <div
                                className="fv-plugins-message-container"
                                style={{ color: "red" }}
                              >
                                <div className="fv-help-block">
                                  {formik.errors.expiryDate}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                          Commission State
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <select
                            placeholder="Select Commission state"
                            className={`form-control form-control-lg form-control-solid ${getInputClasses(
                              "commissionState"
                            )}`}
                            name={`notaryCommissions[${i}].commissionState`}
                            {...formik.getFieldProps(
                              "notaryCommissions[" + i + "].commissionState"
                            )}
                            disabled={disabled}
                          >
                            <option value="none">
                              Select Commission State
                            </option>
                            {allStates &&
                              allStates.rows.map(el => (
                                <option
                                  value={el.state}
                                  selected={
                                    commision.commissionState &&
                                    commision.commissionState == el.state
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {el.state}
                                </option>
                              ))}
                          </select>
                          {formik.touched.commissionState &&
                          formik.errors.commissionState ? (
                            <div
                              className="fv-plugins-message-container"
                              style={{ color: "red" }}
                            >
                              <div className="fv-help-block">
                                {formik.errors.commissionState}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {0 != i && (
                          <div className="col-lg-3 col-xl-3">
                            <input
                              type="button"
                              class="btn btn-secondary"
                              onClick={() => remove_fields(commision.id, i)}
                              value="Remove"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })} */}
              {/* <hr /> */}
              {/* begin::Form Group */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Type Of Notary
                </label>
                <div className="col-lg-9 col-xl-6">
                  <MultiSelect
                    closeOnSelect={false}
                    clearable={false}
                    options={typeOfNotary}
                    onChange={handleChangeCheckBoxTypeNotary}
                    style={{ width: "100%" }}
                    className="form-control form-control-lg form-control-solid owais"
                    name="notaryType"
                    value={typeNotary}
                    labelledBy="CheckList"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Preferred Language
                </label>
                <div className="col-lg-9 col-xl-6">
                  <MultiSelect
                    options={options}
                    onChange={handleChangeCheckBox}
                    style={{ width: "100%" }}
                    className="form-control form-control-lg form-control-solid owais"
                    name="Languages"
                    value={lang}
                    labelledBy="CheckList"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label mt-5">
                  Passport/DL
                </label>
                <div className="col-lg-9 col-xl-6 dottedBorder">
                  <Dropzone
                    onDrop={passportDoc}
                    maxSize={10485760}
                    maxFiles={1}
                    accept="application/pdf, application/docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*, text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="form-control form-control-lg form-control-solid"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <CloudUploadIcon
                          style={{
                            fontSize: "50px",
                            marginLeft: 200,
                            marginTop: -14
                          }}
                        />
                      </div>
                    )}
                  </Dropzone>
                  {user.notaryProfile && user.notaryProfile.passport_file ? (
                    <span style={{ color: "green" }}>
                      {user.notaryProfile.passport_file.filename}
                    </span>
                  ) : (
                    <span style={{ color: "dark" }}>
                      Allowed file types: all files except executions files
                      (e.g. ".exe")
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label mt-5">
                  E&O Insurance Policy
                </label>
                <div className="col-lg-9 col-xl-6 dottedBorder">
                  <Dropzone
                    onDrop={insurancePolicy}
                    maxSize={10485760}
                    maxFiles={1}
                    accept="application/pdf, application/docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*, text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="form-control form-control-lg form-control-solid"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <CloudUploadIcon
                          style={{
                            fontSize: "50px",
                            marginLeft: 200,
                            marginTop: -14
                          }}
                        />
                      </div>
                    )}
                  </Dropzone>
                  {user.notaryProfile && user.notaryProfile.insurance_policy ? (
                    <span style={{ color: "green" }}>
                      {user.notaryProfile.insurance_policy.filename}
                    </span>
                  ) : (
                    <span style={{ color: "dark" }}>
                      Allowed file types: all files except executions files
                      (e.g. ".exe")
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label mt-5">
                  Commission Expiration Date
                </label>
                <div className="col-lg-9 col-xl-6 owais">
                  <TextField
                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                      "notaryExpiry"
                    )}`}
                    id="date"
                    type="date"
                    color="secondary"
                    name="notaryExpiry"
                    {...formik.getFieldProps("notaryExpiry")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0]
                    }}
                  />
                  {formik.touched.notaryExpiry && formik.errors.notaryExpiry ? (
                    <div
                      className="fv-plugins-message-container"
                      style={{ color: "red" }}
                    >
                      <div className="fv-help-block">
                        {formik.errors.notaryExpiry}
                      </div>
                    </div>
                  ) : null}

                  <div
                    className="fv-plugins-message-container"
                    style={{ color: "black" }}
                  >
                    <div className="fv-help-block">
                      Please enter the date in the format i.e: 09/09/9999
                    </div>
                  </div>
                </div>
              </div>
              {/* begin::Form Group */}
            </div>
          </div>
          {/* end::Form */}
        </form>
      ) : (
        <AccountInformation />
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(NotaryProfile));
