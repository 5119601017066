/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import * as actions from "../../../../../app/components/_redux/notarization_redux/notarizationActions";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const MIDDLEWARE_URL = process.env.REACT_APP_MIDDLEWARE_URL;

  const { auth, notarization } = useSelector((state) => state);
  const [istempurl, setistempurl] = useState(false);
  const [istemplinkurl, setistemplinkurl] = useState(false);
  const { templateURL, templateURLinsecure } = useSelector(
    (state) => state.notarization
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const callTemplateURL = () => {
    if (templateURL == null) {
      dispatch(actions.getTemplateURL(auth.user.id, headers));
      setistempurl(true);
    } else window.open(templateURL);
  };

  useEffect(() => {
    /*	 if((auth) && (auth.user) && (auth.user.notaryEmail) && (auth.user.notaryEmail.refreshToken) && (!istempurl)){
     
	  	dispatch(actions.getTemplateURL( auth.user.id, headers ));		
		setistempurl(true);
	 }
	 
	 else if(localStorage.getItem("adobeCredentials") && (!istempurl)){
		 
		let adobeCredentials = 	localStorage.getItem("adobeCredentials");
		let adobeCredentialsarr = adobeCredentials.split("|");
		
		if(auth.user.id == adobeCredentialsarr[0]){
				
				dispatch(actions.getTemplateURL( auth.user.id, headers ));		
				setistempurl(true);
		}
		
		
	}
	*/

    /*	 if((templateURLinsecure) && (!localStorage.getItem("adobeCredentials")))
	 window.open(templateURLinsecure)
	*/

    if (templateURL != null && !istemplinkurl) {
      window.open(templateURL);
      setistemplinkurl(true);
    }
  }, [dispatch, templateURL, templateURLinsecure]);

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
		
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {auth.user.Role.code === 'admin' &&
                <>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/myadmin')}`} >
                        <NavLink className="menu-link" to="/myadmin">
                            <span className="menu-text">Notary Users</span>
                        </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/myadminuser')}`} >
                        <NavLink className="menu-link" to="/myadminuser">
                            <span className="menu-text">Users</span>
                        </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/myadminnotarization')}`} >
                        <NavLink className="menu-link" to="/myadminnotarization">
                            <span className="menu-text">Notarization</span>
                        </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/AdminStateSetting')}`} >
                        <NavLink className="menu-link" to="/AdminStateSetting">
                            <span className="menu-text">State Settings</span>
                        </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/IdologySettings')}`} >
                        <NavLink className="menu-link" to="/IdologySettings">
                            <span className="menu-text">Idology Settings</span>
                        </NavLink>
                    </li>
                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/NotaryVerificationSettings')}`} >
                        <NavLink className="menu-link" to="/NotaryVerificationSettings">
                            <span className="menu-text">Notary Verification Settings</span>
                        </NavLink>
                    </li>
                </>
            }

        {(auth.user.Role.code === "user" ||
          (auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled) ||
          auth.user.Role.code === "ps") && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/dashboard"
            )}`}
          >
            <NavLink className="menu-link" to="/#/dashboard">
              <span className="menu-text">Dashboard</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        )}

        {auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/jobboard"
            )}`}
          >
            <NavLink className="menu-link" to="/jobboard">
              <span className="menu-text">Job Board</span>
            </NavLink>
          </li>
        )}

        {(auth.user.Role.code === "user" ||
        auth.user.Role.code === "notary" ||
        auth.user.Role.code === "ps") && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/journals"
            )}`}
          >
            <NavLink className="menu-link" to="/journals">
              <span className="menu-text">Journals</span>
            </NavLink>
          </li>
        )}

        {auth.user.Role.code === "user" && (
          <>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/payments"
              )}`}
            >
              <NavLink className="menu-link" to="/payments">
                <span className="menu-text">Payments</span>
              </NavLink>
            </li>
            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/chooseNotaries')}`} >
                        <NavLink className="menu-link" to="/chooseNotaries">
                            <span className="menu-text">Search Notaries</span>
                        </NavLink>
                    </li> */}
          </>
        )}

        {(auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled) || auth.user.Role.code === "ps" ? (
          <>
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                "/mypayments"
              )}`}
            >
              <NavLink className="menu-link" to="/mypayments">
                <span className="menu-text">Payments</span>
              </NavLink>
            </li>
            {auth.user.Role.code === "notary" && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/myclients"
                )}`}
              >
                <NavLink className="menu-link" to="/myclients">
                  <span className="menu-text">My Clients</span>
                </NavLink>
              </li>
            )}

            {auth.user.Role.code === "ps" && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/mynotaries"
                )}`}
              >
                <NavLink className="menu-link" to="/mynotaries">
                  <span className="menu-text">My Notaries</span>
                </NavLink>
              </li>
            )}

            {auth.user.Role.code === "notary" && (
              <li
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  "/witnessing"
                )}`}
              >
                <NavLink className="menu-link" to="/witnessing">
                  <span className="menu-text">Witnessing</span>
                </NavLink>
              </li>
            )}
            <li className={`menu-item menu-item-rel`}>
              <NavLink className="menu-link" to="/journals">
                <span
                  className="menu-text"
                  onClick={() =>
                    window.open("https://notary.cyberizeit.com/training/")
                  }
                >
                  Training
                </span>
              </NavLink>
            </li>

            <li className={`menu-item menu-item-rel`}>
              {/*(auth) && (auth.user) && (auth.user.Role)  &&  (auth.user.Role.code == 'notary') &&  (auth.user.notaryEmail) &&  (!auth.user.notaryEmail.refreshToken) &&  (!localStorage.getItem("adobeCredentials")) &&  <a className="menu-link" onClick={() => callTemplateURL()}>
                            <span className="menu-text"  >Create Template</span>
                        </a>*/}

              {/*(((auth) && (auth.user) && (auth.user.Role)  &&  (auth.user.Role.code == 'notary') &&  (auth.user.notaryEmail) &&  (auth.user.notaryEmail.refreshToken)) ||  ((templateURL != null))) ?  <a className="menu-link" onClick={() => window.open(templateURL)} >
                            <span className="menu-text" >Create Template</span>
                        </a>:""*/}

              {auth &&
                auth.user &&
                auth.user.Role &&
                auth.user.Role.code == "notary" && (
                  <a
                    className="menu-link"
                    onClick={() =>
                      window.open(
                        "https://na3.documents.adobe.com/account/addDocumentToLibrary"
                      )
                    }
                  >
                    <span className="menu-text">Create Template</span>
                  </a>
                )}
            </li>

            
          </>
        ) : null}

        {(auth.user.Role.code === "user" ||
          ( auth.user.Role.code === "notary" && !auth.user.notaryProfile.isCancelled ) ||
          auth.user.Role.code === "ps") && (
          <li
            className={`menu-item menu-item-rel ${getMenuItemActive(
              "/deletedjournals"
            )}`}
          >
            <NavLink className="menu-link" to="/deletedjournals">
              <span className="menu-text">Deleted Notarization</span>
            </NavLink>
          </li>
        )}

         
              

        {auth &&
          auth.user &&
          auth.user.Role &&
          auth.user.Role.code == "notary" && (
            <li className={`menu-item menu-item-rel`}>
              <a
                className="menu-link"
                onClick={() =>
                  window.open("https://notary.cyberizeit.com/my-account/afwc-dashboard/")
                }
              >
                <span className="menu-text">Subscription Management</span>
              </a>
            </li>
          )}
        
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
