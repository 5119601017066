import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../components/_redux/notarization_redux/notarizationActions";
import { Form , Card, Button, Row, Col } from "react-bootstrap";
import ShowNotification from '../components/new_notarization/ShowNotification'

export function NotaryVerificationSettings() {
  const dispatch = useDispatch();
  const { auth, notarization } = useSelector(state => state);
  const [verificationUrls, setVerificationUrls] = useState([]);
 
  const [notification, setNotification] = useState({
    show: false,
    msg: '',
    variant: '',
  })

  const [coloradoUrl, setColoradoUrl] = useState("");


  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') return
    setNotification({ show: false, msg: '', variant: '' })
  }

  const updateUrl = (state, url) => {

    let body = {
      "state": state,
      "url": url

      
    }

    dispatch(actions.saveVerificationUrl(body,headers))
    .then((response)=>{
      setNotification({
        show: true,
        msg: "Settings Updated",
        variant: "success"
      });
    })
    .catch(()=>{
      setNotification({
        show: true,
        msg: "Something Went Wrong",
        variant: "error"
      });
    })

  }

  useEffect(() => {
    dispatch(
        actions.getVerificationUrls(headers)
    ).then(data => {
      setColoradoUrl(data.data.verificationUrls[0].url)
      setVerificationUrls(data.data.verificationUrls);
    })
  }, [])

  

  return (
    <div className="container">
      {notification.show && (
        <ShowNotification
          show={notification.show}
          msg={notification.msg}
          variant={notification.variant}
          close={handleCloseNotification}
        />
      )}
      <Row className="justify-content-center mb-10">
        <Col xs={12} md={10}>
          <Card>
            <Card.Header as="h5">Notary Verification Settings</Card.Header>
            <Card.Body>
              
              {verificationUrls.map((url, i) => {         
                  return (
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{url.state} URL</Form.Label>
                        <Form.Control type="text" autoComplete="off" placeholder="Enter url" value={coloradoUrl} onChange={(e)=>setColoradoUrl(e.target.value)}/>
                      </Form.Group>
                    
                      <Button 
                        variant="primary"
                        onClick={()=> {updateUrl(url.state, coloradoUrl)}}
                      >
                        Update
                      </Button>
                  </Form>
                  ) 
                })}
                
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      
    </div>
  );
}