import React, { useState } from "react";
import { useDispatch } from 'react-redux'
import { Modal } from "react-bootstrap";
import * as actions from '../../components/_redux/notarization_redux/notarizationActions';
import { Paynow1 } from "./modals/Paynow1";
import { Paynow2 } from "./modals/Paynow2";
export function PaynowModals({
  payNowModal,
  setPayNowModal,
  payPlatformFeeHandler,
  platformFee,
  paynow,
  paid,
  noOfSigner,
  auth,
  draftHandler,
  scheduleHandler,
  notarizeNowHandler,
  user,
  selectedNotary,
  confirmjobPostHandler,
  showReNotary,
  singerInfo,
  notarizeNowitHandler,
  KBASettings, 
  isBiometric, 
  isButtonClicked,  
}) {


  const headers = {
    Authorization: `Bearer ${auth.authToken}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
}
  const dispatch = useDispatch()

  const [confirm, setConfirm] = useState(false);
  const [selectedNotaryUpdated, setSelectedNotaryUpdated] = useState(selectedNotary);
  const checkNotaryStatus = () => {
    if(auth.user.Role.code === "notary" || auth.user.Role.code === "ps"){
      setConfirm(true);
    }
    else{
      dispatch(actions.fetchPrimaryNotary(headers)).then((data) => {
        if(!data.User?.notaryProfile?.isApproved){
          setSelectedNotaryUpdated({
            primary: false,
            primaryNotary: '',
          })        
          setConfirm(true);
  
        }
        setConfirm(true);
      
      })
    }
    
  }

  return (
    <Modal
      show={payNowModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="payNowModal"
    >
      {confirm ? (
        <Paynow2
          close={setPayNowModal}
          payPlatformFeeHandler={payPlatformFeeHandler}
          platformFee={platformFee}
          payNowModal={true}
          paynow={paynow}
          paid={paid}
          noOfSigner={noOfSigner}
          auth={auth}
          draftHandler={draftHandler}
          scheduleHandler={scheduleHandler}
          notarizeNowHandler={notarizeNowHandler}
          user={user}
          selectedNotary={selectedNotaryUpdated}
          confirmjobPostHandler={confirmjobPostHandler}
          showReNotary={showReNotary}
          singerInfo={singerInfo}
          KBASettings={KBASettings}
          isBiometric={isBiometric}
          isButtonClicked={isButtonClicked}
          notarizeNowitHandler={notarizeNowitHandler}
        />
      ) : (
        <Paynow1 confirm={checkNotaryStatus} close={setPayNowModal} />
      )}
    </Modal>
  );
}
