import { Divider, makeStyles, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../components/_redux/notarization_redux/notarizationActions'
import ShowNotification from '../../../components/new_notarization/ShowNotification'
import { ViewAdditionalInformation } from '../../../components/modals/ViewAdditionalInformation'
import TextField from '@material-ui/core/TextField'
import { LaptopWindows } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/Check'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import moment from "moment";


const useStyles = makeStyles(() => ({
    draft: {
        minWidth: '110px',
        backgroundColor: '#524440',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    progress: {
        minWidth: '110px',
        backgroundColor: '#eee',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    completed: {
        minWidth: '110px',
        backgroundColor: '#bef2de',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    scheduled: {
        minWidth: '110px',
        backgroundColor: '#ff9a00',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    initiated: {
        minWidth: '110px',
        backgroundColor: '#ffff83',
        color: '#000',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    pending: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    paid: {
        minWidth: '110px',
        backgroundColor: '#4ABD54',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    failed: {
        minWidth: '110px',
        backgroundColor: '#EA001B',
        color: '#fff',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '9px 5px',
    },
    alignCenter: {
        textAlign: 'center',
    },
    buttonStyle: {
        backgroundColor: '#1840d2',
        borderColor: '#1840d2',
        minWidth: '165px',
        float: 'right',
        marginBottom: '10px',
    },
}))

export function CustomerEditForm({ onHide }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { socket, auth, notarization } = useSelector((state) => state)
    const [signerLinks, setsignerLinks] = useState({})
    const [copyLinkURL, setcopyLinkURL] = useState('')
    const [bypassReason, setbypassReason] = useState('')
    const [data, setdata] = useState(null)
    const [isbypasskba, setisbypasskba] = useState(0)
    const {
        allAdminNotarization,
        paymentDetails,
        SingleJournalObj,
        jitsiMeetingLinks,
        languages,
        notaryTypes,
        allAdminStates
    } = notarization
    const [notification, setNotification] = useState({
        show: false,
        msg: '',
        variant: '',
    })
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(
        false
    )
    const [prefstate, setprefstate] = useState('')
    const [typeNotary, setTypeNotary] = useState('')

    const [dateTime, setDateTime] = React.useState(
        new Date(new Date().getTime() + 30 * 60000).toISOString()
    )
    const [lang, setLang] = useState([])

    const [RescheduleMode, setRescheduleMode] = useState(false); 

    const handleChangeState = (event) => {
        setprefstate(event.target.value)
    }
    const handleChangeCheckBox = (event) => {
        let index = event.nativeEvent.target.selectedIndex;
        let label = event.nativeEvent.target[index].text;
        setLang([{ id: event.target.value }])
    }
    const handleChangeCheckBoxTypeNotary = (event) => {
        setTypeNotary(event.target.value)
    }
    const dateTimeHandler = (e) => {
        if (Date.parse(new Date(e)) < Date.parse(new Date())) {
            setDateTime(new Date())
        } else setDateTime(new Date(e))
    }

    const rescheduleDateTimeHandler = (e) => {
        setrescehduleDate(new Date(e))
    }

    const headers = {
        Authorization: `Bearer ${auth.authToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
    useEffect(() => {

        setprefstate(data?.state ? data.state : "")
        setTypeNotary(data?.notaryType ? data.notaryType : "")
        setLang([{ id: data?.journalLangs.length && data.journalLangs[0]?.languageId ? data.journalLangs[0].languageId : "" }])
        setDateTime(data?.scheduledAt ? new Date(data.scheduledAt).toISOString() : "" )
    
    }, [data])

    const [rescehduleDate, setrescehduleDate] = useState(new Date())

    const acceptscheduleNotification = () => {
        callscheduleNotification('accepted')
    }

    const reschedulescheduleNotification = () => {
        callscheduleNotification('rescheduled')
    }

    const cancelscheduleNotification = () => {
        callscheduleNotification('rejected')
    }

    const callscheduleNotification = async (acceptbool) => {
        const datastr = {
            notaryResponse: acceptbool,
        }
        if ('rescheduled' == acceptbool)
            datastr['scheduleDate'] = new Date(rescehduleDate).toISOString()
        

        dispatch(actions.acceptSchedule(headers, datastr, data.id)).then(
            (scheduleresponse) => {
                if (
                    scheduleresponse &&
                    scheduleresponse.data &&
                    scheduleresponse.data.status
                ) {
                    setTimeout(function() {
                        window.location.reload()
                    }, 3000)
                }
            }
        )
    }

    useEffect(() => {
        if (SingleJournalObj != null) {
            setdata(SingleJournalObj)
        }
        dispatch(actions.getLanguages(headers))
        dispatch(actions.fetchNotaryTypes(headers))
        
        let biometricEnabled = '';
        for (let index = 0; index < SingleJournalObj?.Signers.length; index++) {
            const signer = SingleJournalObj?.Signers[index];
            if(signer.biometric) {
                biometricEnabled = true;
                break;
            }
        }
        fetchEnabledStates(biometricEnabled);

    }, [SingleJournalObj])

    const fetchEnabledStates = (isEnabled = '') => {
        dispatch(
            actions.fetchNotaryStates(
                headers,
                [`biometricEnabled=${isEnabled}`],
                {
                    pageNumber: 1,
                    pageSize: 52,
                    sortBy: "asc",
                    sortField: "state",
                }
            )
        )
    }

    const prepareTime = (dt) => {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let date = new Date(dt)
        let time = formatAMPM(date)
        let mon = months[date.getMonth()]
        let curDate = date.getDate()
        let year = date.getFullYear()
        let timezone = seconds_with_leading_zeros(date)
        return `${mon} ${curDate}, ${year} at ${time} (${timezone})`
    }

    const formatAMPM = (date) => {
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var ampm = hours >= 12 ? 'PM' : 'AM'
        hours = hours % 12
        hours = hours ? hours : 12
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        return strTime
    }

    const seconds_with_leading_zeros = (dt) => {
        return /\((.*)\)/.exec(dt.toString())[1]
    }

    const joinMeeting = (meetingURL) => {
        window.open(meetingURL, '_blank', 'noopener,noreferrer');
    }

    const copyLink = (event, id) => {
        
        let el = document.createElement('textarea')
        let x = event.target;
        
        el.value = document.location.origin + `/#/id-proofing/?id=${id}`;
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        
        x.innerHTML = "Copied !";
        setTimeout(() => {
            x.innerHTML = "ID proofing"
        }, 2000, x);
    }

    const bypassKBAMode = (id) => {
        setisbypasskba(id)
    }

    const bypassKBAfinalize = () => {
        let body = {
            signerId: isbypasskba,
            bypassReason: bypassReason,
            journalId: data.id,
        }

        const headers = {
            Authorization: `Bearer ${auth.authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

        let journalID = data.id

        dispatch(actions.bypassKBA(body, headers)).then((data) => {
            setisbypasskba(0)

            dispatch(actions.reloadcall(journalID))
        })
    }

    const setReason = (e) => {
        setbypassReason(e.target.value)
    }

    const getCurrentLink = (obj) => {
        let temporaryLink = obj.temporaryLink
        if (
            socket.idologyLink != null &&
            socket.idologyLink['signerId'] === obj.id
        ) {
            if (!signerLinks[obj.id]) {
                let currentsigners = signerLinks
                currentsigners[obj.id] = socket.idologyLink['link']
                setsignerLinks(currentsigners)
            }

            temporaryLink = socket.idologyLink['link']
        } else if (signerLinks[obj.id]) temporaryLink = signerLinks[obj.id]

        return temporaryLink
    }

    const getTempLinkTitle = (obj) => {
        let linkhref = getCurrentLink(obj)
        if (obj.biometric) return 'Copy Biometric Link'
        if (linkhref) {
            if (linkhref.indexOf('idologylive') > -1)
                return 'Copy ID Upload Link'
            else return 'Copy KBA Link'
        }
    }

    const setSignerLink = (obj) => {
        let signLink = getCurrentLink(obj)
        return signLink.replace(/&amp;/g, '&')
    }

    const getPricingStructure = () => {
        // Seals Calculation
        let objcalc = []

        if (data) {
            let sealPayment = 0
            if (
                data &&
                data.Payments &&
                data.Payments[0] &&
                data.Payments[0].userId == data.notaryId
            )
                sealPayment = data.sealCount ? data.sealCount - 1 : 0
            else sealPayment = data.sealCount ? (data.sealCount - 1)*10+25 : 0

            objcalc.push(<p>Seal Payment: ${sealPayment}</p>)

            let withnessamount = data.Witnesses.length * 5
            objcalc.push(<p>Witness Amount: ${withnessamount}</p>)

            let signeramount =
                data.Signers.length < 5 ? 0 : (data.Signers.length - 4) * 3
            objcalc.push(<p>Signers Amount: ${signeramount}</p>)
        }

        return objcalc
    }

    let paymentBreakDown = null
    let filteredWitnesses = []
    if (data !== null) {
        filteredWitnesses =
            data.Witnesses.length > 0
                ? data.Witnesses.filter((el) => el.status !== 'rejected')
                : []
        // paymentBreakDown = GetFinalPayment(
        //     data.sealCount !== null ? data.sealCount : 0,
        //     filteredWitnesses.length
        // )
        paymentBreakDown = 0;
    }

    let rejectedDate = null
    let nowDate = Date.parse(new Date())
    if (data && data.rejectedUntil !== null) {
        rejectedDate = Date.parse(data.rejectedUntil)
    }

    let totalPrice = '--'
    if (data !== undefined && data != null && data.Payments.length > 0) {
        totalPrice = 0
        for (var i = 0; i < data.Payments.length; i++) {
            totalPrice += parseFloat(data.Payments[i].amount) / 100
        }
        totalPrice = '$' + totalPrice.toFixed(2)
    }

    const getRecordingName = (recordingType) => {
        let recordingTypestr = recordingType.replace(/_/g, ' ')
        return recordingTypestr
            .toLowerCase()
            .replace(/\b[a-z]/g, function (letter) {
                return letter.toUpperCase()
            })
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') return
        setNotification({ show: false, msg: '', variant: '' })
    }

    const overridePreferenceFilter = () => {
        let body = {
            journalId: data.id,            
            state: prefstate,
            notaryType: typeNotary,
            scheduledAt: dateTime,
            languages: lang,
        }
        dispatch(actions.overridePreferenceFilter(body, headers))
        .then((response)=>{
            setNotification({
                show: true,
                msg: response.data.message,
                variant: 'success',
            })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })

    }

    const idology = (data, type, signerID = '') => {
        
        const filtered = data?.SignerAuths?.filter((item) => ( item.type === type && item.signerId === signerID && item.callbackResponse !== null ) )
        if (
            filtered?.length &&
            filtered[filtered.length - 1].callbackResponse &&
            ['biometric', 'idScan', 'kba'].includes(type) 
        ) {
            return callBackResult(
                filtered[filtered.length - 1].callbackResponse,
                type
            )
        }
        return '-'
    }

    return (
        <>
            {notification.show && (
                <ShowNotification
                    show={notification.show}
                    msg={notification.msg}
                    variant={notification.variant}
                    close={handleCloseNotification}
                />
            )}

            {SingleJournalObj != null ? (
                <Modal.Body className='overlay overlay-block cursor-default'>
                    {data !== null && data !== undefined && (
                        <>
                            { 
                                data.status === "rescheduled" && (
                                    <div class="alert alert-warning text-center" role="alert">
                                        Reschedule Requested This notarization will not start until the other party accepts the appointment time.
                                    </div>
                                )
                            }
                            {
                                data.status === "pending" && (
                                    <div class="alert alert-warning text-center" role="alert">
                                        The notarization is saved and will begin once the party starts the process.
                                    </div>
                                )
                            }
                            <div className='container text-center'>
                                <h4><strong>Notarization: </strong> {data.id}</h4>
                                <hr />
                            </div>
                            <Table responsive borderless={true}>
                                <tr>
                                    <th className='text-center'>
                                        START&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>
                                        END&nbsp;DATE&nbsp;&&nbsp;TIME
                                    </th>
                                    <th className='text-center'>STATUS</th>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        {data.scheduledAt !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.scheduledAt)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        {data.endDate !== null ? (
                                            <p className='mb-0'>
                                                {prepareTime(data.endDate)}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                    <td>
                                        <p className={classes[data.status]}>
                                            {data.status === 'progress' ||
                                            data.status === 'draft'
                                                ? (data.subStatus === null || data.subStatus === "")
                                                    ? data.status
                                                    : data.subStatus ===
                                                        'rejected'
                                                    ? 'Failed'
                                                    : data.subStatus
                                                : data.status
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    data.status.slice(1)}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <th>Document&nbsp;(s)</th>
                                    <th>Type of Notary Act Performed</th>
                                    <th> Total # of Seals/Acts</th>
                                </tr>
                                <>
                                    {
                                        ['scheduled-unclaimed'].includes(data.status) &&
                                        data.journalDocs ? (
                                            <>
                                                {data.journalDocs.map((el, index) => (
                                                    <tr>
                                                        <td>
                                                            <p
                                                                className='wrapWord'
                                                                key={el.id}>
                                                                <a
                                                                    href={
                                                                        el.File
                                                                            .baseUrl +
                                                                        '/' +
                                                                        el.File.path
                                                                    }
                                                                    target='_blank'
                                                                >
                                                                    {el.File.filename
                                                                        .substring(14)
                                                                        .split(' ')
                                                                        .map((el) => (
                                                                            <>
                                                                                {el}
                                                                                &nbsp;
                                                                            </>
                                                                        ))}
                                                                </a>
                                                                <span>
                                                                    {el.docType ? (
                                                                        <>
                                                                            (
                                                                            {el.docType}
                                                                            ){' '}
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </span>
                                                            </p>
                                                        </td>
                                                        <td>{el.notaryType}</td>
                                                        <td>
                                                            {el.sealCount
                                                                ? el.sealCount
                                                                : '-'}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    data.journalDocs.map((el, index) => (
                                                        <tr>
                                                            <td>
                                                                <p
                                                                    className='wrapWord'
                                                                    key={el.id}>
                                                                    {el.File.filename
                                                                        .substring(14)
                                                                        .split(' ')
                                                                        .map((el) => (
                                                                            <>{el}&nbsp;</>
                                                                        ))}
                                                                </p>
                                                            </td>
                                                            <td>{el.notaryType}</td>
                                                            <td>
                                                                {el.sealCount
                                                                    ? el.sealCount
                                                                    : '-'}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                    {/* <td>{data.type === '' ? '-' : data.type}</td> */}
                                </>
                            </Table>
                            <Divider />
                            <Table responsive borderless={true}>
                                <thead>
                                    <tr>
                                        <th>SIGNER&nbsp;NAME</th>
                                        <th>EMAIL/PHONE</th>

                                        {data.status === 'progress' && (data.subStatus == 'Authenticating Participants' || data.subStatus == 'Awaiting Notary Response') ? (
                                            <>
                                                <th>ADDRESS</th>
                                                <th>DOB</th>
                                                <th>LAST 4 of SSN</th>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        
                                        <th>OVERRIDE ID PROOFING</th>                                        
                                        <th>REASON</th>
                                        <th>KBA</th>
                                        <th>ID UPLOAD</th>
                                        <th>BIOMETRICS</th>
                                        <th>LINKS:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.Signers.map((el) => {
                                        return (
                                            <>
                                                {' '}
                                                <tr key={el.id}>
                                                    <td>
                                                        {el.firstName}{' '}
                                                        {el.lastName}
                                                    </td>
                                                    <td>{el.email}<br/>{el.phone}</td>
                                                    {data.status === 'progress' && (data.subStatus == 'Authenticating Participants' || data.subStatus == 'Awaiting Notary Response') ? (
                                                        <>
                                                           
                                                            <td>{el.address}{', '}{el.city}{', '}{el.state}{', '}{el.zip}</td>                                                
                                                            <td>{el.dob != "" && el.dob != null ? moment(el.dob).format('MM/DD/YYYY') : "-"}</td>                                     
                                                            <td>{el.ssnLast4}</td>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <td>
                                                        {el.bypassKBA
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </td>
                                                    <td>
                                                        {el.bypassKBA
                                                            ? el.bypassReason
                                                            : '-'}
                                                    </td>
                                                    <td>
                                                        {
                                                            idology(
                                                                el,
                                                                'kba',
                                                                el.id
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {idology(
                                                            el,
                                                            'idScan',
                                                            el.id
                                                        )}
                                                    </td>
                                                    <td>
                                                        {idology(
                                                            el,
                                                            'biometric',
                                                            el.id
                                                        )}
                                                    </td>

                                                    {data.subStatus === 'Authenticating Participants' ? (
                                                        <td>
                                                            {
                                                                (el.pageAttempts > 3) && (
                                                                    <Button 
                                                                        variant='warning'
                                                                        disabled={true}
                                                                        style={{
                                                                            marginRight:'5px',
                                                                        }}
                                                                    >
                                                                        Limit Exceeded
                                                                    </Button>
                                                                )
                                                            }
                                                            {
                                                                el.status === 'pending' &&
                                                                !el.bypassKBA && (
                                                                    <Button 
                                                                            variant='success'
                                                                            onClick={(event) => copyLink(event, el.id)}
                                                                            style={{
                                                                                marginRight:'5px',
                                                                            }}
                                                                        >
                                                                            ID proofing
                                                                    </Button>
                                                                )
                                                            }
                                                            <span>
                                                                {el.status !==
                                                                    'verified' &&
                                                                !el.bypassKBA ? (
                                                                    <Button
                                                                        variant='success'
                                                                        id={
                                                                            'byPassKBA' +
                                                                            el.id
                                                                        }
                                                                        onClick={() =>
                                                                            bypassKBAMode(
                                                                                el.id
                                                                            )
                                                                        }>
                                                                        Override
                                                                    </Button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                        </td>
                                                    ) : (
                                                        <td>&nbsp; -</td>
                                                    )}
                                                </tr>
                                                {isbypasskba === el.id &&
                                                    !el.bypassKBA && (
                                                        <tr>
                                                            <td colspan='4'>
                                                                <div>
                                                                    <textarea
                                                                        name='reason'
                                                                        onChange={
                                                                            setReason
                                                                        }
                                                                        style={{
                                                                            width:
                                                                                '100%',
                                                                            height:
                                                                                '200px',
                                                                        }}></textarea>
                                                                    <Button
                                                                        variant='success'
                                                                        onClick={() =>
                                                                            bypassKBAfinalize(
                                                                                el.id
                                                                            )
                                                                        }>
                                                                        Submit
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            {auth && auth.user && filteredWitnesses.length > 0 && (
                                <>
                                    <Divider />
                                    <Table responsive borderless={true}>
                                        <thead>
                                            <tr>
                                                <th>WITNESS&nbsp;NAME</th>
                                                <th>WITNESS&nbsp;EMAIL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredWitnesses.map((el) => {
                                                return (
                                                    <tr key={el.id}>
                                                        <td>
                                                            {el.User.firstName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                            {el.User.lastName
                                                                .split(' ')
                                                                .map((el) => (
                                                                    <>
                                                                        {el}
                                                                        &nbsp;
                                                                    </>
                                                                ))}
                                                        </td>
                                                        <td>{el.User.email}</td>
                                                        {/* <td>{el.IP === "" ? '-' : el.IP}</td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                            <Divider />
                            <Table responsive borderless={true}>
                                <thead>
                                    <tr>
                                        <th>PRICE</th>
                                        <th>NOTARY&nbsp;</th>
                                        <th>SESSION&nbsp;VIDEO</th>
                                        {data.status === 'scheduled' && (
                                            <th>SCHEDULED</th>
                                        )}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <td>
                                        {data.Payments.length > 0
                                            ? data.Payments.map((el, i) => (
                                                  <>
                                                      {data &&
                                                      data.Payments &&
                                                      data.Payments[0] &&
                                                      data.Payments[0].userId == data.notaryId ? (
                                                          el.title ==
                                                          'Final Payment' ? (
                                                              getPricingStructure()
                                                          ) : (
                                                              <p>
                                                                  {el.title}: $
                                                                  {el.amount /
                                                                      100}
                                                              </p>
                                                          )
                                                      ) : (
                                                          '-'
                                                      )}
                                                  </>
                                              ))
                                            : '-'}
                                        </td>
                                        </td>
                                        <td>
                                            {data.notary !== null
                                                ? data.notary.firstName
                                                    .split(' ')
                                                    .map((el) => (
                                                        <>{el}&nbsp;</>
                                                    ))
                                                : ''}
                                            {data.notary !== null
                                                ? data.notary.lastName
                                                    .split(' ')
                                                    .map((el) => (
                                                        <>{el}&nbsp;</>
                                                    ))
                                                : ''}

                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                auth.user.Role.code !== 'notary' &&
                                                data.notary !== null ? (
                                                <span>
                                                    <br />{' '}
                                                    <strong className='EmailSameLine'>
                                                        Email:{' '}
                                                    </strong>
                                                    {data.notary.email
                                                        ? data.notary.email
                                                        : '-'}
                                                    <br />{' '}
                                                    <strong>Phone: </strong>
                                                    {data.notary.phone
                                                        ? data.notary.phone
                                                        : '-'}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td>{jitsiMeetingLinks?.length > 0
                                        ? jitsiMeetingLinks.map(
                                            (el, i) => (
                                                
                                                <>
                                                    <a
                                                        href={
                                                            el.s3link
                                                        }
                                                        target='_blank'
                                                        >
                                                        Part - {++i}
                                                    </a>
                                                    <br />
                                                </>
                                            )
                                        )
                                        : '-'}
                                    </td>
                                        {data.status === 'scheduled' && (
                                            <td>
                                                {data.scheduledAt !== null
                                                    ? prepareTime(
                                                        data.scheduledAt
                                                    )
                                                    : '-'}
                                            </td>
                                        )}

                                        <td
                                            className='pl-0 pr-0'
                                            style={{ float: 'right' }}>
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                auth.user.Role.code === 'notary' ? (
                                                ''
                                            ) : (
                                                <span></span>
                                            )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                auth.user.Role.code === 'notary' ? (
                                                <span></span>
                                            ) : (
                                                ''
                                            )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                data.status === 'progress' &&
                                                data.Meeting &&
                                                data.Meeting.jitsiUrl ? (
                                                <>
                                                    <span></span>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                data.status === 'progress' &&
                                                !data.Meeting ? (
                                                <span></span>
                                            ) : (
                                                ''
                                            )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role.code &&
                                                data.status === 'completed' && (
                                                    <>
                                                        <span></span>
                                                    </>
                                                )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role.code ===
                                                'notary' &&
                                                data.status === 'progress' &&
                                                data.subStatus ===
                                                'Accepted' && (
                                                    <>
                                                        <span></span>
                                                    </>
                                                )}
                                            {auth &&
                                                auth.user &&
                                                auth.user.Role.code ===
                                                'notary' &&
                                                data.agreementId &&
                                                'completed' === data.status && (
                                                    <>
                                                        <span></span>
                                                    </>
                                                )}

                                            {auth &&
                                                auth.user &&
                                                auth.user.Role &&
                                                auth.user.Role.code !== 'notary' &&
                                                data.subStatus ===
                                                'Authentication Passed' ? (
                                                <span></span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Grid container spacing={2} className='p-2'>
                                <Grid item sm={5}></Grid>
                                <Grid item sm={7}>
                                    {auth &&
                                    auth.user &&
                                    auth.user.Role &&
                                    data.status === 'progress' &&
                                    data?.Meeting &&
                                    data?.Meeting.status === 'started' &&
                                    data?.Meeting?.jitsiUrl ? (
                                        <>
                                            <Button
                                                className={`mr-0 ml-0 pl-0 pr-0 ${classes.buttonStyle}`}
                                                onClick={() =>
                                                    joinMeeting(
                                                        data?.Meeting?.jitsiUrl
                                                    )
                                                }>
                                                Join Jitsi Meeting
                                            </Button>
                                            <br />
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    {auth &&
                                    auth.user &&
                                    auth.user.Role &&
                                    data.status === 'progress' &&
                                    (!data.Meeting  || (data.Meeting && data.Meeting.status != "started"))? (
                                        <span>
                                            <strong>
                                                The Notary will send a link to join them in meeting shortly, please watch here or access the link in your email. You will need to have your camera and microphone on during this meeting.
                                            </strong>
                                            <br />
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className='p-2'>
                                <Grid item sm={5} spacing={5}>
                                    <h5>TOTAL PRICE: {totalPrice}</h5>
                                </Grid>

                                {((auth &&
                                    auth.user &&
                                    auth.user.Role.code &&
                                    data.status === 'scheduled') ||
                                    data.status === 'rescheduled') && (
                                        <>
                                            <Button
                                                className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                                onClick={() =>
                                                    setRescheduleMode(true)
                                                }
                                                >
                                                Reschedule
                                            </Button>
                                            <br />
                                        </>
                                    )}
                                {auth &&
                                auth.user.Role &&
                                auth.user.Role.code !== 'notary' &&
                                data.subStatus === 'by notary' &&
                                (data.status === 'scheduled' ||
                                    data.status ===
                                        'rescheduled') && (
                                    <>
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                acceptscheduleNotification()
                                            }
                                            >
                                            Accept
                                        </Button>
                                        <Button
                                            className={`mr-0 ml-0 pl-0 pr-0  mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                cancelscheduleNotification()
                                            }
                                            >
                                            Reject
                                        </Button>
                                    </>
                                )}

                                <Grid item sm={5}>
                                    {data.status === 'completed' && (
                                        <Button
                                            className={`mr-0 ml-0 mr-3 ${classes.buttonStyle}`}
                                            onClick={() =>
                                                setShowAdditionalInformation(
                                                    true
                                                )
                                            }>
                                            View Extended Journal Detail
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>

                            {/* only show for scheduled unclaimed notarizations */}
                            {data && data.status === "scheduled-unclaimed" && (
                                <>
                                    <Divider />
                                    <Grid className='p-5'>
                                        <div className='form-group row'>
                                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                                Date and Time
                                            </label>
                                            <div className='col-lg-9 col-xl-6'>
                                                <MuiPickersUtilsProvider utils={MomentUtils }>
                                                    <DateTimePicker 
                                                        label="Date and Time"
                                                        initialFocusedDate={new Date(data.scheduledAt)}
                                                        value={dateTime}
                                                        onChange={dateTimeHandler}
                                                        showTodayButton={true}
                                                        disablePast={true}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                                Preferred Language
                                            </label>
                                            <div className='col-lg-9 col-xl-6'>
                                                <select
                                                    placeholder='Select state'
                                                    className='form-control form-control-lg form-control-solid owais'
                                                    name='state'
                                                    // value={data.journalLangs.length ? data.journalLangs[0].languageId : ""}
                                                    onChange={handleChangeCheckBox}
                                                >
                                                    <option value='' selected>
                                                        Select Languages
                                                    </option>
                                                    {languages &&
                                                        languages.map((el) => (
                                                            <option selected={data.journalLangs.length && data.journalLangs[0].languageId && data.journalLangs[0].languageId === el.value ? "selected" : ""} value={el.value} label={el.label}>
                                                                {el.label}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                                Type Of Notary
                                            </label>
                                            <div className='col-lg-9 col-xl-6'>
                                                <select
                                                    placeholder='Select state'
                                                    className='form-control form-control-lg form-control-solid owais'
                                                    name='state'
                                                // value={data.notaryType ? data.notaryType : ""}
                                                    onChange={handleChangeCheckBoxTypeNotary}
                                                >
                                                    <option value='' selected>
                                                        Select Type of Notary
                                                    </option>

                                                    {notaryTypes &&
                                                        notaryTypes.map((el) => (
                                                            <option selected={data.notaryType && data.notaryType === el.value ? "selected" : ""} value={el.value}>
                                                                {el.label}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <label className='col-xl-3 col-lg-3 col-form-label'>
                                                State
                                            </label>
                                            <div className='col-lg-9 col-xl-6'>
                                                <select
                                                    placeholder='Select state'
                                                    className='form-control form-control-lg form-control-solid owais'
                                                    name='state'
                                                // value={data.state ? data.state : ""}
                                                    onChange={handleChangeState}
                                                >
                                                    <option value='' selected>
                                                        Select State
                                                    </option>
                                                    {allAdminStates &&
                                                        allAdminStates.rows.map((el) => (
                                                            <option selected={data.state && data.state === el.state ? "selected" : ""} value={el.state}>
                                                                {el.state}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className='col-lg-9 col-xl-6'>
                                                <Button
                                                    variant='success'
                                                    onClick={overridePreferenceFilter}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {showAdditionalInformation && (
                        <ViewAdditionalInformation
                            openModal={showAdditionalInformation}
                            onHide={onHide}
                            data={data}
                        />
                    )}

                    {RescheduleMode ? (
                        <>                                                       
                            <div class='flex_class flex_class-alt'>
                                <MuiPickersUtilsProvider utils={MomentUtils }>
                                    <DateTimePicker 
                                        label="Date and Time"
                                        initialFocusedDate={rescehduleDate}
                                        value={rescehduleDate}
                                        onChange={rescheduleDateTimeHandler}
                                        showTodayButton={true}
                                        disablePast={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className='modal-body-two'>
                                <Button
                                    variant='secondary'
                                    className='acceptBtn'
                                    onClick={
                                        reschedulescheduleNotification
                                    }
                                    >
                                    Submit
                                </Button>
                            </div>
                        </>
                                ) : (
                                    ''
                                )}
                </Modal.Body>
            ) : (
                ''
            )}
        </>
    )
}

const callBackResult = (callbackResponse, type) => {
    let parsed = "" 
    if("string" == typeof(callbackResponse)) {
        try {
            parsed = JSON.parse(callbackResponse) 
        } catch (e) {
            parsed = callbackResponse;
        }
    }
    else {
        parsed = JSON.parse(JSON.stringify(callbackResponse));
    }

    if (type === 'kba' && parsed) {
        if (parsed === 'Ineligible') {
            return <p style={{ color: 'red' }}>{parsed}</p>
        }
        if( parsed['idliveq-result']?.key && parsed['idliveq-result']?.key?._text == "result.timeout" ) {
            return (
                <p style={{ color: 'red'}}>
                    -
                </p>
            )
        }

        if(parsed.error){
            return (
                <p style={{ color: `${5 - parsed <= 3 ? 'red' : 'green'}` }}>
                    0 out of 5
                </p>
            )
        }

        parsed = parsed['idliveq-result']?.key?._text.split('.')[2] //result.timeout
        if (parsed === 0) {
            return <CheckIcon />
        }
        
        return (
            <p style={{ color: `${5 - parsed <= 3 ? 'red' : 'green'}` }}>
                {5 - parsed} out of 5
            </p>
        )
    }

    if ( type === 'biometric' && parsed ) {
        //for old idology notarizations
        if( parsed['located-id-scan-record'] && parsed['located-id-scan-record']['id-scan-facial-match-score']?._text ) {
            let score = parsed['located-id-scan-record']['id-scan-facial-match-score']?._text;
            let status = "";
            if ( parsed['id-scan-verification-result'] && parsed['id-scan-verification-result']?.message?._text ) {
                status = parsed['id-scan-verification-result']?.message?._text;
            }
            return (
                <>
                    <p style={{ color: `${score < 80 ? 'red' : 'green'}` }}>
                        {score}%
                    </p>
                    {
                        status && (
                            <p style={{ color: `${score < 80 ? 'red' : 'green'}` }}>
                                {status}
                            </p>
                        )
                    }
                </>
            )
        }
        else if( parsed['id-scan-result'] && parsed['id-scan-result']['message'] ) {
            let score = parsed['id-scan-result']['message']?._text;
            return (
                <p style={{ color: 'red' }}>
                    {score}
                </p>
            )
        }
        //for persona based notarizations
        else if(parsed.attributes) {
            let status = "";
            switch(parsed.attributes.status){
                case "needs_review":
                case "failed": 
                    status = "Failed";
                    break;
                case "expired":
                    status = "Expired";
                    break;
                case "approved":
                    status = "Approved";
                    break;
                default: 

            }
            return (
                <p style={{ color: `${parsed.attributes.status != 'approved' ? 'red' : 'green'}` }}>
                    {status}
                </p>
            )
        }

    }

    if ( type === 'idScan' && parsed ) {
       
        if ( parsed['id-scan-verification-result'] && parsed['id-scan-verification-result']?.message?._text ) {
            return (
                <p style={{ color: `${parsed['id-scan-summary-result'].message._text == "FAIL" ? 'red' : 'green'}` }}>
                    {parsed['id-scan-verification-result'].message._text}
                </p>
            )
        }
        else if( parsed['id-scan-result'] && parsed['id-scan-result']['message'] ) {
            let score = parsed['id-scan-result']['message']?._text;
            return (
                <p style={{ color: 'red' }}>
                    {score}
                </p>
            )
        }
        //for persona based notarizations
        else if(parsed.attributes) {
            let status = "";
            switch(parsed.attributes.status){
                case "needs_review":
                case "failed": 
                    status = "Failed";
                    break;
                case "expired":
                    status = "Expired";
                    break;
                case "approved":
                    status = "Approved";
                    break;
                default: 

            }
            return (
                <p style={{ color: `${parsed.attributes.status != 'approved' ? 'red' : 'green'}` }}>
                    {status}
                </p>
            )
        }
    }
    return '-'
}
